import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { applyMiddleware, compose } from 'redux';


// Configure the store
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in non-production environments
});

export default store;
