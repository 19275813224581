import React, { useState } from 'react';
import { auth } from '../firebase';  // Firebase config
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux/authActions';  // Import the action
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';  // Ensure correct import path for CSS

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const token = await user.getIdTokenResult();
      const isAdmin = token.claims.admin || false;
      
      // Extract only serializable data
      const userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        isAdmin: isAdmin
      };

      console.log('User logged in:', userData);

      // Dispatch only serializable user data
      dispatch(loginSuccess(userData));

      // Redirect to main after successful login
      navigate('/main');
    } catch (err) {
      console.error('Login error:', err);
      setError('Login failed. Please check your email and password.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
