import Cookies from 'js-cookie';

// Action types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

// Action creators
export const loginSuccess = (user) => {
  
Cookies.set('loginInfo', JSON.stringify(user), { expires: 1 / 24 });

  return {
    type: 'LOGIN_SUCCESS',
    payload: {
      uid: user.uid,
      role: user.isAdmin ? 'admin' : 'user',  // Set the role based on custom claim
    },
  };
};


export const logoutAction = () => {
  // Remove login cookie
  Cookies.remove('loginInfo');

  return {
    type: 'LOGOUT'
  };
};