import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finishSession, fetchAssistantResponse, sendFeedback, clearChat, submitSessionFeedback } from '../redux/actions'; // Added submitSessionFeedback
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import ConfirmationModal from './ConfirmationModal';
import './ChatScreen.css';  // Import the updated CSS
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'; // Icons for thumbs up and down

const ChatScreen = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.chat.messages);
  const [input, setInput] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [feedback, setFeedback] = useState({}); // Track feedback for each message
  const [comments, setComments] = useState({}); // Track comments for each message
  const [feedbackSubmitted, setFeedbackSubmitted] = useState({}); // Track feedback submission status

  const handleSendMessage = () => {
    if (input.trim()) {
      dispatch(fetchAssistantResponse(input));
      setInput('');  // Clear input after sending the message
    }
  };

  const handleFinishSession = () => {
    setModalOpen(true);  // Open the confirmation modal
  };

  const handleConfirmFinish = ({ feedback, comment }) => {
    // Submit session feedback before finishing the session
    dispatch(submitSessionFeedback(feedback, comment));
    dispatch(finishSession());
    dispatch(clearChat());  // Clear the chat when session finishes
    setModalOpen(false);  // Close the modal
  };

  const handleSkipFeedback = () => {
    // Finish the session without submitting feedback
    dispatch(finishSession());
    dispatch(clearChat());
    setModalOpen(false);  // Close the modal
  };

  const handleCancelFinish = () => {
    setModalOpen(false);  // Close the modal
  };

  // Handle thumbs up/down for a specific message (questionId)
  const handleThumbs = (questionId, feedbackType) => {
    setFeedback((prev) => ({ ...prev, [questionId]: feedbackType }));
  };

  // Handle comment change for a specific message (questionId)
  const handleCommentChange = (questionId, value) => {
    setComments((prev) => ({ ...prev, [questionId]: value }));
  };

  // Handle sending feedback for a specific message (questionId)
  const handleSendFeedback = (questionId) => {
    const feedbackType = feedback[questionId];
    const comment = comments[questionId] || '';
    dispatch(sendFeedback(feedbackType, comment));  // Dispatch feedback to the server
    setFeedbackSubmitted((prev) => ({ ...prev, [questionId]: true }));  // Mark feedback as submitted
  };

  const renderMessage = (text) => {
    const html = DOMPurify.sanitize(marked.parse(text));
    return { __html: html };
  };

  return (
    <div className="chat-screen">
      <div id="chat-header">
        <h2>Chat with Hr Muller</h2>
        <button onClick={handleFinishSession}>Finish Session</button>
      </div>
      <div id="chat-body">
        {messages.map((msg, index) => {
          const isAssistantMessage = msg.sender === 'assistant';
          const questionId = `q-${index}`; // Generate a questionId based on the index

          return (
            <div key={index} className={`chat-message ${msg.sender}`}>
              <div dangerouslySetInnerHTML={renderMessage(msg.text)} />

              {/* Show feedback options for assistant messages only after assistant has answered */}
              {isAssistantMessage && msg.text !== 'Thinking...' && !feedback[questionId] && !feedbackSubmitted[questionId] && (
                <div className="feedback-section">
                  <button className="feedback-btn" onClick={() => handleThumbs(questionId, 'thumbs_up')}>
                    <FaThumbsUp /> Thumbs Up
                  </button>
                  <button className="feedback-btn" onClick={() => handleThumbs(questionId, 'thumbs_down')}>
                    <FaThumbsDown /> Thumbs Down
                  </button>
                </div>
              )}

              {/* Show feedback input box after thumbs up/down is selected */}
              {feedback[questionId] && !feedbackSubmitted[questionId] && (
                <div className="comment-section">
                  <textarea
                    placeholder="Leave a comment (optional)"
                    value={comments[questionId] || ''}
                    onChange={(e) => handleCommentChange(questionId, e.target.value)}
                  />
                  <button className="send-feedback-btn" onClick={() => handleSendFeedback(questionId)}>
                    Send Feedback
                  </button>
                </div>
              )}

              {/* Show thank you message after feedback is submitted */}
              {feedbackSubmitted[questionId] && (
                <div className="feedback-thanks">
                  Thanks for your feedback!
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div id="chat-input">
        <input
          type="text"
          placeholder="Ask Hr Muller..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>

      {/* Render the custom confirmation modal with the skip feedback option */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmFinish}
        onCancel={handleCancelFinish}
        onSkipFeedback={handleSkipFeedback}  // Add skip feedback handler
      />
    </div>
  );
};

export default ChatScreen;
