import { START_SESSION, FINISH_SESSION } from './actions';

const initialState = {
  isSessionActive: false,
};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SESSION:
      return {
        ...state,
        isSessionActive: true,
      };
    case FINISH_SESSION:
      return {
        ...state,
        isSessionActive: false,
      };
    default:
      return state;
  }
};
