import React from 'react';
import { LineChart, Line, PieChart, Pie, BarChart, Bar, Cell, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';

const AdminDashboard = () => {
  // Mock data for charts
  const sessionData = [
    { day: 'Monday', sessions: 30 },
    { day: 'Tuesday', sessions: 50 },
    { day: 'Wednesday', sessions: 40 },
    { day: 'Thursday', sessions: 60 },
    { day: 'Friday', sessions: 20 },
  ];

  const feedbackDistribution = [
    { name: 'Thumbs Up', value: 70 },
    { name: 'Thumbs Down', value: 30 },
  ];

  const recentSessionFeedback = [
    { sessionId: '12345', success: 'Yes', feedback: 'The assistant worked perfectly!' },
    { sessionId: '67890', success: 'No', feedback: 'Response time was too slow.' },
    { sessionId: '11223', success: 'Yes', feedback: 'Very helpful!' },
  ];

  const COLORS = ['#00C49F', '#FF8042']; // For pie chart colors

  return (
    <div className="admin-dashboard" style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <h1>Admin Dashboard</h1>

      {/* Overview and Feedback Summary */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px', gap: '20px' }}>
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Total Sessions</h3>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>200</p>
        </div>
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Total Feedback</h3>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>170</p>
        </div>
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Session Success Rate</h3>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>85%</p>
        </div>
      </div>

      {/* Session Trends and Feedback Distribution */}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Session Trends (This Week)</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={sessionData}>
              <XAxis dataKey="day" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="sessions" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <h3>Feedback Distribution</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={feedbackDistribution}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {feedbackDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Recent Session Feedback */}
      <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <h3>Recent Session Feedback</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Session ID</th>
              <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Success</th>
              <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Feedback</th>
            </tr>
          </thead>
          <tbody>
            {recentSessionFeedback.map((feedback, index) => (
              <tr key={index}>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{feedback.sessionId}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{feedback.success}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{feedback.feedback}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
