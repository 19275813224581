import { LOGIN_SUCCESS, LOGOUT } from './authActions';

const initialState = {
  isAuthenticated: false,
  user: null,
  role: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        role: action.payload.role,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        role: null,
      };
    default:
      return state;
  }
};

export default authReducer;
