import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import StartScreen from './components/StartScreen';
import ChatScreen from './components/ChatScreen';
import LoginPage from './components/LoginPage';
import AdminDashboard from './components/AdminDashboard'; 
import { loginSuccess } from './redux/authActions'; // Import your loginSuccess action
import Cookies from 'js-cookie';

const App = () => {
  const dispatch = useDispatch();

  // Check if the session is active and user is authenticated
  const isSessionActive = useSelector((state) => state.session.isSessionActive);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Check for the loginInfo cookie on app load and dispatch login success if it exists
  useEffect(() => {
    const loginInfo = Cookies.get('loginInfo');
    if (loginInfo) {
      const user = JSON.parse(loginInfo);
      dispatch(loginSuccess(user));  // Dispatch login success to update the Redux state
    }
  }, [dispatch]);  // Empty array ensures this runs once on component mount

  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Redirect user to main page if logged in, otherwise to login page */}
          <Route path="/" element={isAuthenticated ? <Navigate to="/main" /> : <Navigate to="/login" />} />
          
          {/* Login Page Route */}
          <Route path="/login" element={<LoginPage />} />
          
          {/* Main Page Route (Start Screen) */}
          <Route
            path="/main"
            element={
              isAuthenticated ? (isSessionActive ? <ChatScreen /> : <StartScreen />) : <Navigate to="/login" />
            }
          />
          
          {/* Admin Dashboard Route */}
          <Route
            path="/admin-dashboard"
            element={isAuthenticated ? <AdminDashboard /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
