import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import './ConfirmationModal.css';  // Import the new CSS file

// Set the app element to avoid accessibility issues
Modal.setAppElement('#root');

const FeedbackModal = ({ isOpen, onConfirm, onCancel, onSkipFeedback }) => {
  const [feedback, setFeedback] = useState('');  // "thumbs_up" or "thumbs_down"
  const [comment, setComment] = useState('');  // Optional comment

  const handleThumbsUp = () => setFeedback('thumbs_up');
  const handleThumbsDown = () => setFeedback('thumbs_down');

  const handleConfirm = () => {
    onConfirm({ feedback, comment });  // Pass feedback and comment to the confirm handler
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Session Feedback"
      className="modal-content"  // Apply the class from CSS
      overlayClassName="ReactModal__Overlay"  // Apply overlay class for proper positioning
    >
      <h2 className="modal-title">Finish Session & Provide Feedback</h2>
      <p className="modal-description">How was your session? Please provide your feedback or skip it.</p>

      <div className="modal-buttons">
        <button
          className={`feedback-btn ${feedback === 'thumbs_up' ? 'thumbs-up' : ''}`}
          onClick={handleThumbsUp}
        >
          <FaThumbsUp style={{ marginRight: '8px' }} /> Thumbs Up
        </button>
        <button
          className={`feedback-btn ${feedback === 'thumbs_down' ? 'thumbs-down' : ''}`}
          onClick={handleThumbsDown}
        >
          <FaThumbsDown style={{ marginRight: '8px' }} /> Thumbs Down
        </button>
      </div>

      {/* Optional comment input field */}
      {feedback && (
        <textarea
          placeholder="Leave a comment (optional)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="textarea-comment"
        />
      )}

      <div className="modal-buttons">
        <button
          onClick={handleConfirm}
          className="confirm-btn"
          disabled={!feedback}  // Disable button if feedback is not selected
        >
          Submit Feedback
        </button>
        <button
          onClick={onSkipFeedback}
          className="skip-btn"
        >
          Skip Feedback
        </button>
        <button
          onClick={onCancel}
          className="cancel-btn"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
