import { combineReducers } from 'redux';
import { sessionReducer } from './sessionReducer';
import { chatReducer } from './chatReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  chat: chatReducer,
  auth: authReducer
});

export default rootReducer;
