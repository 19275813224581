export const START_SESSION = 'START_SESSION';
export const FINISH_SESSION = 'FINISH_SESSION';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const FETCH_ASSISTANT_RESPONSE_SUCCESS = 'FETCH_ASSISTANT_RESPONSE_SUCCESS';
export const FETCH_ASSISTANT_RESPONSE_ERROR = 'FETCH_ASSISTANT_RESPONSE_ERROR';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_QUESTION_ID = 'SET_QUESTION_ID';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SUBMIT_SESSION_FEEDBACK = 'SUBMIT_SESSION_FEEDBACK'; // New action for session-level feedback

// Action to start a chat session and generate a session ID
export const startSession = () => {
  const sessionId = Math.random().toString(36).substring(7);  // Simple unique ID generator for session
  return {
    type: START_SESSION,
    payload: sessionId,
  };
};

// Action to finish a chat session
export const finishSession = () => ({
  type: FINISH_SESSION,
});

// Action to add a new message to the chat
export const addMessage = (sender, text) => ({
  type: ADD_MESSAGE,
  payload: { sender, text },
});

// Action to clear chat messages
export const clearChat = () => ({
  type: CLEAR_CHAT,
});

// Thunk action to fetch the assistant's response from the web service
export const fetchAssistantResponse = (query) => {
  return (dispatch, getState) => {
    // Dispatch the user's message immediately
    dispatch(addMessage('user', query));

    // Show a loading message while waiting for the response
    dispatch(addMessage('assistant', 'Thinking...'));

    // Retrieve the current sessionId
    const sessionId = getState().chat.sessionId;

    // Generate a unique question ID for this query
    const questionId = Math.random().toString(36).substring(7);
    dispatch({
      type: SET_QUESTION_ID,
      payload: questionId,
    });

    // Call the web service with sessionId and questionId
    fetch('https://lvmhelper.ahmed-ovais.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, sessionId, questionId }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Remove the "Thinking..." message and add the real response
        dispatch({
          type: FETCH_ASSISTANT_RESPONSE_SUCCESS,
          payload: data.response,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle the error and show an error message in the chat
        dispatch({
          type: FETCH_ASSISTANT_RESPONSE_ERROR,
          payload: 'Sorry, an error occurred.',
        });
      });
  };
};

// Thunk action to send question-specific feedback (thumbs up/down and optional comment)
export const sendFeedback = (feedback, comment = '') => {
  return (dispatch, getState) => {
    const { sessionId, questionId } = getState().chat;

    // Call feedback API with sessionId, questionId, feedback, and comment
    fetch('https://symphonytekvatracking.ahmed-ovais.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId, questionId, feedback, comment }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Optional: Dispatch any feedback success actions here
        dispatch({
          type: SEND_FEEDBACK,
          payload: { feedback, comment },
        });
      })
      .catch((error) => {
        console.error('Error sending feedback:', error);
      });
  };
};

// Thunk action to send session-level feedback (thumbs up/down and optional comment)
export const submitSessionFeedback = (feedback, comment = '') => {
  return (dispatch, getState) => {
    const { sessionId } = getState().chat;

    // Call feedback API for session-level feedback
    fetch('https://symphonytekvatracking.ahmed-ovais.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'session_feedback',  // Specify that this is session-level feedback
        sessionId,
        feedback,  // "thumbs_up" or "thumbs_down"
        comment,   // Optional comment
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Dispatch the session feedback action
        dispatch({
          type: SUBMIT_SESSION_FEEDBACK,
          payload: { feedback, comment },
        });
      })
      .catch((error) => {
        console.error('Error submitting session feedback:', error);
      });
  };
};
