import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startSession } from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import './StartScreen.css';  // Updated CSS file

const StartScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);  // Get role from Redux

  return (
    <div className="start-screen">
      <h1>Welcome to Hr Muller Assistant</h1>

      {/* Start Session Button */}
      <button onClick={() => dispatch(startSession())}>Start Session</button>

      {/* Conditionally render Admin Dashboard button for admins */}
      {role === 'admin' && (
        <button onClick={() => navigate('/admin-dashboard')}>Admin Dashboard</button>
      )}
    </div>
  );
};

export default StartScreen;
