import {
  ADD_MESSAGE,
  FETCH_ASSISTANT_RESPONSE_SUCCESS,
  FETCH_ASSISTANT_RESPONSE_ERROR,
  CLEAR_CHAT,
  START_SESSION,
  FINISH_SESSION,
  SET_QUESTION_ID,
  SEND_FEEDBACK,
  SUBMIT_SESSION_FEEDBACK,  // New action for session feedback
} from './actions';

const initialState = {
  sessionId: null,   // For tracking session
  questionId: null,  // For tracking question
  messages: [],      // Chat messages
  feedback: null,    // Storing question-level feedback
  sessionFeedback: null,  // Storing session-level feedback
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SESSION:
      return {
        ...state,
        sessionId: action.payload,  // Set the session ID when the session starts
        messages: [],  // Clear messages for the new session
        feedback: null,  // Clear question-level feedback for the new session
        sessionFeedback: null,  // Clear session-level feedback for the new session
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],  // Add new message to the chat
      };
    case FETCH_ASSISTANT_RESPONSE_SUCCESS:
      return {
        ...state,
        // Remove the last message (which is "Thinking...") and add the real response
        messages: [
          ...state.messages.slice(0, -1),
          { sender: 'assistant', text: action.payload },
        ],
      };
    case FETCH_ASSISTANT_RESPONSE_ERROR:
      return {
        ...state,
        // Replace "Thinking..." with the error message
        messages: [
          ...state.messages.slice(0, -1),
          { sender: 'assistant', text: action.payload },
        ],
      };
    case SET_QUESTION_ID:
      return {
        ...state,
        questionId: action.payload,  // Set the question ID when a new query is sent
      };
    case SEND_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,  // Store question-level feedback when thumbs up/down and comment are submitted
      };
    case SUBMIT_SESSION_FEEDBACK:
      return {
        ...state,
        sessionFeedback: action.payload,  // Store session-level feedback (success or failure)
      };
    case CLEAR_CHAT:
      return {
        ...state,
        messages: [],  // Clear all chat messages
      };
    case FINISH_SESSION:
      return {
        ...initialState,  // Reset the state to initial state when the session finishes
      };
    default:
      return state;
  }
};
